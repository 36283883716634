var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dados-bancarios p-4 pt-8" },
    [
      _c(
        "vs-row",
        {
          staticClass: "mb-5",
          attrs: { "vs-type": "flex", "vs-justify": "space-between" }
        },
        [
          _c("vs-col", { staticClass: "md:w-1/2 w-full" }, [
            _c("h3", [_vm._v("Gestão de Usuários")])
          ]),
          _vm.podeGerenciar
            ? _c(
                "vs-col",
                {
                  staticClass: "md:w-1/2 w-full",
                  attrs: { "vs-type": "flex", "vs-justify": "end" }
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: { click: _vm.cadastrarUsuarios }
                    },
                    [_vm._v("\n        Cadastrar Usuário\n      ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("listar-usuarios-component", {
        attrs: {
          usuarios: _vm.usuarios,
          podeGerenciar: _vm.podeGerenciar,
          podeVisualizar: _vm.podeVisualizar
        },
        on: {
          editarUsuario: _vm.editarUsuario,
          visualizarUsuario: _vm.visualizarUsuario
        }
      }),
      _c("detalhe-usuario-modal", {
        attrs: {
          user: _vm.usuario,
          canChange: _vm.canChange,
          showModal: _vm.showModal,
          userTypeId: _vm.profileHelpers.userTypeEnum.BROKER
        },
        on: { closeModal: _vm.closeModal, loadUsuarios: _vm.loadUsuarios }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }