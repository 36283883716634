<template>
  <div class="cadastro-corretor new-tabs">
    <div class="breadcrumb mb-6">
      <breadcrumb
        title="Corretor"
        :actualPage="dadosCorretor.BrokerName"
        previousPage="corretores"
        previousPageTitle="Lista de Corretores"
      />
    </div>

    <div class="perfil-corretor">
      <div class="unblock" v-if="dataUnblock">
        <div class="icon">
          <i class="el-icon-warning-outline"></i>
        </div>
        <div class="text">
          <b>ConsultasPJ bloqueada</b>
          <p>Limite máximo de consultas por mês.</p>
        </div>
        <div class="button">
          <el-button
            id="unblock"
            round
            @click="popupUnblock = true"
            style="    padding: 5px 17px;"
          >
            Desbloquear</el-button
          >
        </div>
      </div>
      <vs-popup title="" :active.sync="popupUnblock">
        <div class="flex flex-col  pt-4">
          <h2
            class="text-center"
            style="color: #737373; font-size: 19px; font-weight: bold; margin-top: -30px; margin-bottom: 40px;"
          >
            Desbloquear Corretora
          </h2>
          <p class="text-center">
            Para realizar o desbloqueio, será necessário justificar o motivo
            abaixo.
          </p>
          <p
            class="label-input text-left"
            style="font-weight: bold; margin: 10px 0px;"
          >
            Informe o Motivo <b style="color:red">*</b>
          </p>
          <el-input type="textarea" :rows="6" v-model="reasonUnblock">
          </el-input>
          <div
            class="vx-row flex justify-left mt-5"
            style="    justify-content: flex-end; padding-right: 15px;"
          >
            <div>
              <vs-button
                style="color: gray; background: white!important; border: 1px solid #cfcfcf; border-radius: 8px!important; padding: 15px 35px;"
                class="mr-1"
                @click="
                  popupUnblock = false;
                  reasonUnblock = '';
                "
              >
                <span class="font-semibold">
                  Cancelar
                </span>
              </vs-button>
              <vs-button
                style="border-radius: 8px!important; padding: 15px 35px;"
                @click.native="sendUnblock()"
                :disabled="reasonUnblock.length <= 0"
              >
                <span class="font-semibold"> Enviar e Desbloquear</span>
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
      <vs-tabs color="warning" class="min-h-550">
        <vs-tab label="Cadastro Completo">
          <dados-gerais
            v-if="dadosCorretor && dadosCorretor.BrokerId"
            :corretor="dadosCorretor"
            :financeiro="dadosFinanceiro"
            @updateFinancialData="atualizaDadosFinanceiros"
            @atualizarDadosCadastrais="atualizarDadosCadastrais"
          />
        </vs-tab>
        <!-- <vs-tab label="Comissão"> TODO PBI 3432
          <dados-comissao :brokerId="dadosCorretor.BrokerId" v-if="dadosCorretor && dadosCorretor.BrokerId" />
        </vs-tab> -->
        <vs-tab label="Nomeações">
          <dados-nomeacao
            :brokerId="dadosCorretor.BrokerId"
            v-if="dadosCorretor && dadosCorretor.BrokerId"
          />
        </vs-tab>

        <vs-tab label="Sócios">
          <TableSocios :Socios="dadosCorretor.BrokerUniqueId" />
        </vs-tab>

        <vs-tab label="Termos de Aceite">
          <TermosDeAceite :dadosCorretor="dadosCorretor" />
        </vs-tab>

        <!-- <vs-tab label="Produção">

        </vs-tab>

        <vs-tab label="Carteira">

        </vs-tab> -->

        <vs-tab
          label="Histórico de Alterações"
          v-if="dadosCorretor.BrokerId && perfilHelpers.isInsurance()"
        >
          <historico-alteracao :id="dadosCorretor.BrokerId" />
        </vs-tab>

        <!-- <vs-tab
          label="Histórico de Consultas"
          v-if="isEssor && dadosCorretor.BrokerId && perfilHelpers.isInsurance()"
        >
          <historico-consultas :document="DocumentNumber" />
        </vs-tab> -->

        <vs-tab label="Histórico de Consultas" v-if="exibirHistoricoConsultasCorretorPorBureau && dadosCorretor.BrokerId && perfilHelpers.isInsurance()">
          <HistoricoConsultasCorretor :document="DocumentNumber" />
        </vs-tab>

        <vs-tab
          label="Histórico de Consultas"
          v-else
        >
          <historico-consultas :document="DocumentNumber" />
        </vs-tab>
        <!-- <vs-tab
          label="Histórico de Callbacks"
          v-if="dadosCorretor.BrokerId && perfilHelpers.isInsurance()"
        >
          <historico-callbacks  :dados="dadosCorretor"  :type="'BROKER'"/>
        </vs-tab> -->
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../layouts/components/breadcrumb/Breadcrumb.vue";
import DadosGerais from "./components/DadosGerais.vue";
import DadosComissao from "./components/DadosComissao.vue";
import DadosNomeacao from "./components/DadosNomeacao";
import HistoricoAlteracao from "./components/HistoricoAlteracao.vue";
import HistoricoConsultas from "../../components/historico-consultas/HistoricoConsultas.vue";
import HistoricoCallbacks from "../../components/historico-callback/index.vue";
import TableSocios from "../../components/vs-table/Table-Socios.vue";
import TermosDeAceite from "../../components/termos-aceite/TermosDeAceite.vue";
import axiosInstance from "../../axios";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import HistoricoConsultasCorretor from "./components/HistoricoConsultasCorretor.vue";
import environment from "@/environment";
import commonProvider from "@/providers/common-provider.js";
import * as Permissoes from "@/utils/permissions.js";

export default {
  components: {
    Breadcrumb,
    DadosGerais,
    DadosComissao,
    DadosNomeacao,
    HistoricoConsultas,
    HistoricoCallbacks,
    HistoricoAlteracao,
    TableSocios,
    TermosDeAceite,
    HistoricoConsultasCorretor
  },
  data() {
    return {
      dadosCorretor: {},
      dadosFinanceiro: {},
      DocumentNumber: {},
      perfilHelpers: PerfilHelpers,
      popupUnblock: false,
      dataUnblock: false,
      reasonUnblock: "",
      isEssor: false,
      permissoes: []
    };
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      await this.carregarFinanceiro();
      await this.carregarCorretor();
      await this.obterModuloPermissoes()
    });
  },
  created() {
    this.$appInsights.trackPageView({
      name: "detalhe-corretor",
      url: window.location.href
    });

    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    let Essor = parseInt(environment.CompanyId.Essor);
    this.isEssor = portalConfig.CompanyId == Essor ? true : false
  },
  computed: {
    exibirHistoricoConsultasCorretorPorBureau() {
      return this.permissoes.includes("ExibirHistoricoConsultasBureau");
    },
  },
  methods: {
    getUnblock(id) {
      return axiosInstance
        .get(`/api/Broker/ObterBloqueioConsulta?brokerId=${id}`)
        .then(response => {
          this.dataUnblock = response.data.Response;
        })
        .catch(ex => {
          this.$onpoint.errorModal(ex.response.data.Errors);
        });
    },
    async sendUnblock() {
      this.$vs.loading();
      let data = {
        BrokerId: this.dadosCorretor.BrokerId,
        Justificativa: this.reasonUnblock
      }
      return await axiosInstance
        .post(`/api/Broker/DesbloquearConsulta`, data)
        .then(async response => {
          this.dataUnblock = response.data.Response;
          this.popupUnblock = false;
          this.$notify({
            title: "Success",
            message: "Desbloqueio Realizado com sucesso!",
            type: "success"
          });
          this.$vs.loading.close();
          await this.carregarCorretor();
        })
        .catch(ex => {
          this.$vs.loading.close();
          this.$onpoint.errorModal(ex.response.data.Errors);
        });
    },

    async carregarFinanceiro() {
      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(
            `api/Broker/GetBrokerFinancialData?brokerUniqueId=${this.$route.params.corretorUniqueId}`
          )
          .then(response => {
            if (response.data.Response)
              this.dadosFinanceiro = response.data.Response;
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },
    async carregarCorretor() {
      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(
            `api/Broker/GetBrokerRegistrationData?brokerUniqueId=${this.$route.params.corretorUniqueId}`
          )
          .then(response => {
            if (response.data.Response) {
              this.dadosCorretor = response.data.Response;
              this.getUnblock(this.dadosCorretor.BrokerId);
              // console.log(" this.dadosCorretor", this.dadosCorretor);
              this.DocumentNumber = response.data.Response.BrokerDocument;
              this.updateListaSocios(response.data.Response.PersonQSAs);
            }
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },

    atualizaDadosFinanceiros(parametrosFinanceiros) {
      this.dadosFinanceiro.BrokerResponsibles.forEach(element => {
        if (element.ResponsiblePersonTypeName == "FinancialOfficer") {
          element.ResponsiblePersonDocument =
            parametrosFinanceiros.FinancialOfficer.CpfCnpj;
          element.ResponsiblePersonEmail =
            parametrosFinanceiros.FinancialOfficer.Email;
          element.ResponsiblePersonPhoneNumber =
            parametrosFinanceiros.FinancialOfficer.PhoneNumber;
          element.ResponsiblePersonName =
            parametrosFinanceiros.FinancialOfficer.Name;
        }
      });

      this.dadosFinanceiro.BankNumber = parametrosFinanceiros.newBankId
        ? parametrosFinanceiros.newBankId.Id
        : parametrosFinanceiros.BankId;
      this.dadosFinanceiro.Branch = parametrosFinanceiros.BankBranchNumber;
      this.dadosFinanceiro.DigitBranch = parametrosFinanceiros.BankBranchDigit;
      this.dadosFinanceiro.Number = parametrosFinanceiros.BankAccountNumber;
      this.dadosFinanceiro.DigitNumber = parametrosFinanceiros.BankAccountDigit;
    },

    atualizarDadosCadastrais(event){
      this.dadosCorretor = event
    },
    async obterModuloPermissoes() {
      this.permissoes = [];
      try {
        this.permissoes = await commonProvider.obterPermissoesModulo(Permissoes.Modulo.CORRETOR);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },
  }
};
</script>

<style lang="scss">
.perfil-corretor {
  position: relative;
  margin-top: 35px;
  .unblock {
    font-size: 14px !important;
    position: absolute;
    right: 20px;
    top: -80px;
    border-radius: 15px;
    background-color: rgb(252, 219, 219);
    border: 1px solid rgb(155, 9, 9);
    padding: 12px 15px;
    display: flex;
    .icon {
      font-size: 27px;
      margin-right: 8px;
    }
    .button {
      margin-left: 5px;
      display: flex;
      align-content: center;
      align-items: center;
    }
  }
  .min-h-550 {
    min-height: 550px;
  }

  .con-vs-tabs {
    display: flex;
  }

  .con-ul-tabs {
    background: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 250px;
    padding: 50px 20px;
    margin-bottom: 10px;
  }

  .line-vs-tabs {
    margin-top: 50px;
    margin-left: 20px;
  }

  .vs-tabs--li button.vs-tabs--btn {
    margin-top: 30px;
    width: auto;
  }

  .vs-tabs--ul {
    box-shadow: none;
    flex-direction: column;
  }

  .vs-tabs--li {
    padding-right: 15px;
  }

  .con-slot-tabs {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 10px;
    margin: 0 20px 10px 5px;
  }
}
</style>
