var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-4 py-4" },
    [
      _c("div", { staticClass: "tab" }, [
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer font-medium w-auto active",
            on: {
              click: function(e) {
                return _vm.openTab(e, "dadosCadastrais")
              }
            }
          },
          [_vm._v("\n      Dados cadastrais\n    ")]
        ),
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer font-medium w-auto",
            on: {
              click: function(e) {
                return _vm.openTab(e, "dadosFinanceiros")
              }
            }
          },
          [_vm._v("\n      Dados financeiros\n    ")]
        ),
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer font-medium w-auto",
            on: {
              click: function(e) {
                return _vm.openTab(e, "dadosUsuarios")
              }
            }
          },
          [_vm._v("\n      Usuários\n    ")]
        ),
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer font-medium w-auto",
            on: {
              click: function(e) {
                return _vm.openTab(e, "documentos")
              }
            }
          },
          [_vm._v("\n      Documentos\n    ")]
        )
      ]),
      _vm.corretor.BrokerName
        ? _c("dados-cadastrais", {
            attrs: { corretor: _vm.corretor },
            on: { atualizarDadosCadastrais: _vm.atualizarDadosCadastrais }
          })
        : _vm._e(),
      _c("dados-financeiros", {
        attrs: { financeiro: _vm.financeiro },
        on: {
          updateFinancialData: function($event) {
            return _vm.$emit("updateFinancialData", $event)
          }
        }
      }),
      _c("dados-usuarios", {
        staticClass: "tabContent",
        attrs: {
          id: "dadosUsuarios",
          cadastro: _vm.dadosUsuarios,
          podeGerenciar: _vm.perfilHelpers.checkPermission([
            _vm.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORES
          ]),
          podeVisualizar: true
        }
      }),
      _vm.corretor && _vm.corretor.BrokerName
        ? _c("documentos", { attrs: { corretor: _vm.corretor } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }