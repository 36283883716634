var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabContent", attrs: { id: "dadosFinanceiros" } },
    [
      _c("dados-bancarios", {
        attrs: {
          cadastro: _vm.financeiro,
          bancos: _vm.bancos,
          accountTypeList: _vm.accountTypeList
        },
        on: {
          updateFinancialData: function($event) {
            return _vm.$emit("updateFinancialData", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }